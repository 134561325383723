import React from 'react';
// import ProfileTimeLine from './ProfileTimeLine';
import FilterGallery from './FilterGallery';
// import showResults from './Show';
// import ProfileSettings from './ProfileSettings';

const ProfileTabs = () => (
  <FilterGallery />
);

export default ProfileTabs;
