import { getTotalUnreadNumberForMenu } from '~/apiCalls/chatApi';
import { client } from '~/apiCalls/directusConfig';
import { apiCallBegan } from '~/store/api';
import { authRefreshed, authRefreshFailed } from '~/store/auth';
import store from '~/store/store.js';
import { idleTracker } from '../idle.ts';
import { chatGetUnRead } from '~/store/chats.js';
import { userDehydrated, userHydrated, userGotSupportedCountry } from '../store/user';
import { isSupportedCountry } from './paymentApi';
import { chatAddMessagBegan, chatAddedMessage } from '../store/chats';

import { getUserDataApi } from './userApi';
import { getMemberDetailsByConversationId } from './chatApi';


export const refresh = async () => {
  const response = await client.refresh();

  store.dispatch(apiCallBegan({
    axiosCall: getUserDataApi,
    onSuccess: userHydrated.type,
  }));
  store.dispatch(apiCallBegan({
    axiosCall: getTotalUnreadNumberForMenu,
    onSuccess: chatGetUnRead.type,
  }));
  store.dispatch(apiCallBegan({
    axiosCall: isSupportedCountry,
    onSuccess: userGotSupportedCountry.type,
  }));
  return response;
};


export const directusLoginCall = async ({ email, password, otp = null }) => {
  const args = [email, password];
  if (otp) {
    args.push({ otp });
  }
  const loginCredit = await client.login(...args);
  if (loginCredit) {
    refresh();
  }
  return loginCredit;
};

let idle = false;
/**
 * Register
 */
export const register = async (credentials) => {
  const registerResult = await client.request(() => ({
    path: 'mail-register',
    method: "POST",
    body: JSON.stringify(credentials),
  }));
  return registerResult;
};

/**
 * Everything that should happen when someone logs out, or is logged out through an external factor
 */
export const Logout = async (optionsRaw) => {
  const defaultOptions = {
    navigate: true,
    reason: 'SIGN_OUT',
  };


  const options = { ...defaultOptions, ...optionsRaw };

  // Only if the user manually signed out should we kill the session by hitting the logout endpoint
  if (options.reason === 'SIGN_OUT') {
    await client.logout();
  }

  // clearTimeout(refreshTimeout);
  store.dispatch(userDehydrated());

  if (options.navigate === true) {
    const location = {
      path: `/login`,
      query: { reason: options.reason },
    };
  }
};

// Prevent the auto-refresh when the app isn't in use
idleTracker.on('idle', () => {
  idle = true;
});

idleTracker.on('hide', () => {
  idle = true;
});

// Restart the autorefresh process when the app is used (again)
idleTracker.on('active', () => {
  if (idle === true) {
    store.dispatch(apiCallBegan({
      axiosCall: refresh,
      onSuccess: authRefreshed.type,
      onError: authRefreshFailed.type,
    }));
    idle = false;
  }
});

idleTracker.on('show', () => {
  if (idle === true) {
    store.dispatch(apiCallBegan({
      axiosCall: refresh,
      onSuccess: authRefreshed.type,
      onError: authRefreshFailed.type,
    }));
    idle = false;
  }
});

export function addQueryToPath(path, query) {
  const queryParams = new URLSearchParams(path.split('?')[1] || '');

  for (const [key, value] of Object.entries(query)) {
    queryParams.set(key, value);
  }

  // eslint-disable-next-line
  return path.split('?')[0] + '?' + queryParams;
}

export const addTokenToURL = async (url) => {
  const accessToken = await client.getToken();

  return accessToken ? addQueryToPath(url, { access_token: accessToken }) : url;
};
