import React from 'react';
import {
  Col, Row
} from 'reactstrap';
import Accepted from './QuoPanel/Accepted';
import Completed from './QuoPanel/Completed';
import Countering from './QuoPanel/Countering';
import Delivered from './QuoPanel/Delivered';
import Pending from './QuoPanel/Pending';
import Inviting from './QuoPanel/Inviting';
import Rating from './QuoPanel/Rating';
import Rejected from './QuoPanel/Rejected';
import TimeOut from './QuoPanel/TimeOut';
import Searching from './QuoPanel/Searching';

const QuoTabs = ({
  quos,
  quosState,
  capbable,
}) => (
  <>
    {quos?.map(q => {
      if (q.current_status === "Inviting") {
        return (<Inviting key={q.id} quo={q} capbable={capbable} />);
      }
      if (q.current_status === "Pending") {
        return (<Pending key={q.id} quo={q} capbable={capbable} />);
      }
      if (q.current_status === "Countering") {
        return (<Countering key={q.id} quo={q} />);
      }
      if (q.current_status === "Rejected") {
        return (<Rejected key={q.id} quo={q} quosState={quosState} />);
      }
      if (q.current_status === "Accepted") {
        return (<Accepted key={q.id} quo={q} quosState={quosState} />);
      }
      if (q.current_status === "Delivered") {
        return (<Delivered key={q.id} quo={q} quosState={quosState} />);
      }
      if (q.current_status === "Timeout") {
        return (<TimeOut key={q.id} quo={q} quosState={quosState} />);
      }
      if (q.current_status === "Rating") {
        return (<Rating key={q.id} quo={q} quosState={quosState} />);
      }
      if (q.current_status === "Completed") {
        return (<Completed key={q.id} quo={q} quosState={quosState} />);
      }
      if (q.current_status === "Searching") {
        return (<Searching key={q.id} quo={q} quosState={quosState} />);
      }
      return ("");
    })}
  </>
);

export default QuoTabs;
