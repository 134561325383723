import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';
import classNames from 'classnames';
import { useParams } from 'react-router-dom';
import getTransText from '~~/get-text/getTransText';
import AccountSettingForm from './components/AccountSettingForm';
import LanguageSettingForm from './components/LanguageSettingForm';
import PasswordSettingForm from './components/PasswordSettingForm';
import ProfileSettingForm from './components/ProfileSettingForm';
import NotificationSetting from './components/NotificationSetting';
import HelpForm from './components/HelpForm';

// import { Card } from '@material-ui/core';

const AccountSetting = () => {
  const {
    userData,
  } = useSelector(state => state.user);
  const { tabActive } = useParams();
  const [activeTab, setActiveTab] = useState(tabActive || 'profile');
  const [collapseToggle, setCollapseToggle] = useState((tabActive));

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
    setCollapseToggle(true);
  };

  const account_setting_card = classNames({
    "account_setting_card": true,
    "open": collapseToggle,
  });


  return (
    <Container className="account_setting">
      <div className="account_setting_wrapper">
        <Nav vertical className="account_setting_menu">
          <NavItem>
            <NavLink
              key={1}
              className={classNames({ active: activeTab === 'profile' })}
              onClick={() => toggle('profile')}
            >
              <p className="sidebar__link-title">{getTransText("Profile")}</p>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              key={2}
              className={classNames({ active: activeTab === 'language' })}
              onClick={() => toggle('language')}
            >
              <p className="sidebar__link-title">{getTransText("Language")}</p>
            </NavLink>
          </NavItem>
          {userData && userData.provider === "default" && (
            <NavItem>
              <NavLink
                key={3}
                className={classNames({ active: activeTab === 'password' })}
                onClick={() => toggle('password')}
              >
                <p className="sidebar__link-title">{getTransText("Password")}</p>
              </NavLink>
            </NavItem>
          )}
          <NavItem>
            <NavLink
              key={4}
              className={classNames({ active: activeTab === 'noti' })}
              onClick={() => toggle('noti')}
            >
              <p className="sidebar__link-title">{getTransText("Notification")}</p>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              key={4}
              className={classNames({ active: activeTab === 'account' })}
              onClick={() => toggle('account')}
            >
              <p className="sidebar__link-title">{getTransText("Account")}</p>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              key={5}
              className={classNames({ active: activeTab === 'contact' })}
              onClick={() => toggle('contact')}
            >
              <p className="sidebar__link-title">{getTransText("Help")}</p>
            </NavLink>
          </NavItem>
        </Nav>

        <Card className={account_setting_card}>
          <CardBody>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="profile">
                <ProfileSettingForm setCollapseToggle={setCollapseToggle} />
              </TabPane>
              <TabPane tabId="language">
                <LanguageSettingForm setCollapseToggle={setCollapseToggle} />
              </TabPane>
              <TabPane tabId="password">
                <PasswordSettingForm setCollapseToggle={setCollapseToggle} />
              </TabPane>
              <TabPane tabId="noti">
                <NotificationSetting setCollapseToggle={setCollapseToggle} />
              </TabPane>
              <TabPane tabId="account">
                <AccountSettingForm setCollapseToggle={setCollapseToggle} />
              </TabPane>
              <TabPane tabId="contact">
                <HelpForm setCollapseToggle={setCollapseToggle} />
              </TabPane>
            </TabContent>
          </CardBody>
        </Card>
      </div>
      <Row>
        <Col md={3}>

        </Col>
        <Col md={9} lg={8}>
        </Col>
        <Col md={0} lg={2}>
        </Col>
      </Row>
    </Container>
  );
};

export default AccountSetting;
