import { createDirectus, rest, authentication, realtime } from '@directus/sdk';
import xfetch from 'isomorphic-fetch';
import xWebSocket from 'isomorphic-ws';
import '@babel/polyfill';

export const client = createDirectus(process.env.REACT_APP_API_URL, {
  globals: {
    WebSocket: xWebSocket,
    fetch: xfetch,
  }
}).with(authentication('cookie', { credentials: 'include' })).with(rest({ credentials: 'include' })).with(realtime());
export const clientPublic = createDirectus(process.env.REACT_APP_API_URL).with(rest());
