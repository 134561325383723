import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { hookAuth0 } from '~~/auth/withAuth0';
import AvatarRender from '~~/avatar_render';

const TopbarProfile = ({ onClick }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const {
    avatar,
    random_no,
    username,
  } = useSelector(state => state.user.userData);
  const [ava, setAva] = useState();
  const auth0 = [];

  const toggleProfile = () => {
    setIsCollapsed(!isCollapsed);
  };

  useEffect(() => {
    setAva(AvatarRender(avatar, random_no));
  }, [avatar, random_no]);
  
  return (
    <div className="sidebar__profile">
      <Link to={`/p/${username}`}>
        <button className="sidebar__avatar" type="button" onClick={onClick}>
          <img
            className="avatar-img"
            src={(auth0.user && auth0.user.picture) || ava}
            alt="avatar"
          />
          <p className="avatar-name">
            { auth0.loading ? 'Loading...' : (auth0.user && auth0.user.name) || (username)}
          </p>
        </button>
      </Link>
    </div>
  );
};

export default hookAuth0(TopbarProfile);
