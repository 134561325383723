import React, { useEffect, useState } from 'react';
import { Pagination } from "react-pagination-bar";
import { useSelector } from 'react-redux';
import { useHistory, useParams } from "react-router-dom";
import { Col, Container, Row } from 'reactstrap';
// import ProfileMain from '../../shared/components/profile/ProfileMain';
// import ProfileCalendar from './components/ProfileCalendar';
import { getQuos } from '~/apiCalls/quoApi';
import getTransText from '~~/get-text/getTransText';
import LoadingLogo from '~~/loading_logo/LoadingLogo.jsx';
import QuoTabs from './components/QuoTabs';
import manageQuoSample from './manageQuoSample.json';
import QuoSVG from '~~/quoSVG/';

const ManageQuoContainer = () => {
  const {
    id,
  } = useSelector(state => state.user.userData);

  const { page } = useParams();
  const [quos, setQuos] = useState(undefined);
  const quosState = { quos, setQuos };
  const history = useHistory();
  const cpage = page || 1;
  const [loaded, setLoaded] = useState(false);

  const setCurrentPage = async (pageNumber) => {
    if (pageNumber.toString() !== page && pageNumber) {
      history.push(`/manageQuo/${pageNumber}`);
    }
  };

  useEffect(async () => {
    if (page !== "demo") {
      if (id) {
        await getQuos(cpage)
          .then((response) => {
            if (response && Object.keys(response).length > 0) {
              setQuos(response);
            } else {
              setQuos([]);
            }
          });
        setLoaded(true);
      }
    } else {
      setQuos(manageQuoSample);
      setLoaded(true);
    }
  }, [id, page]);

  const renderQuoTab = () => {
    if (quos !== undefined && quos?.data?.length === 0) {
      return (
        <Col md={8} className="no-quo">
          {/* <h3 className="page-title">{getTransText("Manage QUO")}</h3> */}
          <div className="badge-icon">
            <span className="icon"><QuoSVG quoClass="icon-quo light-grey" /></span>
            <h5>{getTransText("No QUO yet")}</h5>
            <p>{getTransText("Discover the people with the hot tags now")}</p>
          </div>
        </Col>
      );
    }
    if (quos !== undefined && quos?.data?.length > 0) {
      return (
        <>
          {/* <Col xs={12}>
            <h3 className="page-title">{getTransText("Manage QUO")}</h3>
          </Col> */}
          <QuoTabs quos={quos?.data} quosState={quosState} />
          <Col xs={12}>
            <div className="pagination">
              <Pagination
                initialPage={parseInt(quos.current_page, 10)}
                itemsPerPage={quos.per_page}
                onPageСhange={(pageNumber) => page !== "demo" && setCurrentPage(pageNumber)}
                totalItems={quos.total}
                pageNeighbours={2}
                startLabel="<<"
                endLabel=">>"
                nextLabel=">"
                prevLabel="<"
              />
            </div>
          </Col>
        </>
      );
    }
    if (quos === undefined) {
      return (
        ""
      );
    }
  };

  return (
    <Container className="manage__quo">
      {loaded
        ? (
          <Row>
            <Col className="quos__container">
              {renderQuoTab()}
            </Col>
          </Row>
        )
        : (
          <LoadingLogo />
        )}
    </Container>
  );
};
export default ManageQuoContainer;
