import classNames from 'classnames';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { useSelector } from 'react-redux';
import { Link, useHistory } from "react-router-dom";
import {
  Button,
  ButtonToolbar
} from 'reactstrap';
// import MessageTextOutlineIcon from 'mdi-react/MessageTextOutlineIcon';
import { createConversation } from '~/apiCalls/chatApi';
import { checkIsBookmarked, createBookmark, } from '~/apiCalls/userApi';
import AvatarRender from '~~/avatar_render';
import getTransText, { GetCountryName } from '~~/get-text/getTransText';
import ModalParent from '~~/modal_parent/ModalParent';
import StepCarousel from '~~/share_carousel/';

const ProfileMain = () => {
  const [isBookmarked, setIsBookmarked] = useState(null);
  const history = useHistory();
  const {
    authenticated,
  } = useSelector(state => state.auth);
  const {
    username,
  } = useSelector(state => state.user.userData);
  const {
    userInfo,
  } = useSelector(state => state.profile);
  useEffect(async () => {
    let isCancelled = false;
    if (userInfo) {
      await checkIsBookmarked(userInfo.username)
        .then((bookmarkReturn) => {
          if (!isCancelled) {
            if (bookmarkReturn) {
              setIsBookmarked(bookmarkReturn.isExisted);
            }
          }
        });
    }
    return () => {
      isCancelled = true;
    };
  }, [userInfo]);

  const Ava = () => {
    if (typeof (userInfo) !== 'undefined' && userInfo != null) {
      return (AvatarRender(userInfo?.avatar, userInfo?.random_no));
    }
    return ("");
  };


  const HandleMsgClick = async () => {
    const conversationId = await createConversation(userInfo.username);
    history.push(`/c/${conversationId}`);
  };

  const HandleBookmarkClick = async (action) => {
    const res = await createBookmark(userInfo.username, action);
    if (res?.success) {
      setIsBookmarked(!isBookmarked);
    }
  };

  const succeed = (
    <>
      <Button color="primary" onClick={() => HandleBookmarkClick("cancel")} alt="bookmark" className="btn-bookmark bookmarked">{getTransText("profile.bookmarked")}<i className="las la-bookmark"></i></Button>
      <Button color="primary" className="btn-message" onClick={() => HandleMsgClick()}>{getTransText("profile.message")}<i className="las la-paper-plane"></i></Button>
    </>
  );

  const notsucceed = (
    <>
      <Button color="primary" onClick={() => HandleBookmarkClick("add")} alt="bookmark" className="btn-bookmark">{getTransText("profile.bookmark")}<i className="las la-bookmark"></i></Button>
      <Button color="primary" onClick={() => HandleMsgClick()}>{getTransText("profile.message")}<i className="las la-paper-plane"></i></Button>
    </>
  );

  const rating_bar = classNames({
    "profile__rating": true,
    // "exist": sellerRateShow?.isExist,
  });

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const [copySuccess, setCopySuccess] = useState(getTransText("Copy Profile Link"));
  const [toggler, setToggler] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const renderButton = () => {
    if (userInfo.isMe) {
      return (
        <>
          <Link className="btn btn-outline-primary" to="/accountSetting/profile">{getTransText("profile.edit.profile")}<i className="las la-pen"></i></Link>
          {/* <Dropdown isOpen={dropdownOpen} toggle={toggle} direction={direction}>
            <DropdownToggle color="primary" outline><i className="las la-ellipsis-v"></i></DropdownToggle>
            <DropdownMenu>
              <DropdownItem><Link to="/accountSetting/profile">{getTransText("Edit Profile")}</Link></DropdownItem>
            </DropdownMenu>
          </Dropdown> */}
        </>
      );
    }

    return (isBookmarked ? succeed : notsucceed);
  };

  function onLoad() {
    // delay for demo only
    setIsLoading(false);

    // setIsLoading(false)
  }
  const switchToggler = () => {
    setToggler(!toggler);
  };

  return (
    <>
      <ModalParent toggler={toggler} switchToggler={switchToggler}>
        <StepCarousel />
      </ModalParent>
      {/* <div className="profile__information" style={{ "background-image": `url(${Ava()})` }}> */}
      <div className="profile__information">
        {authenticated && (
          <>
            {
              userInfo.isMe
                ? (
                  <>
                    <ButtonToolbar className="profile__btn-toolbar btn-toolbar--center">
                      <Button
                        color="secondary"
                        className="btn-get_verified"
                      >
                        {getTransText("profile.get.verified")} <i className="las la-check-circle"></i>
                      </Button>
                      {/* <Link className="btn btn-outline-primary control-btn" to="/accountSetting/profile"><i className="las la-pen"></i></Link> */}
                      <Button className="btn-share control-btn" outline color="primary" onClick={() => setToggler(!toggler)}><i className="las la-external-link-alt"></i></Button>
                    </ButtonToolbar>
                  </>
                )
                : (
                  userInfo.supportedCountry && (
                    <ButtonToolbar className="profile__btn-toolbar btn-toolbar--center">
                      <Button className="btn-share control-btn" outline color="primary" onClick={() => setToggler(!toggler)}><i className="las la-external-link-alt"></i></Button>
                    </ButtonToolbar>
                  )
                )
            }
          </>
        )}
        <div className="profile__avatar">
          <label className="click_to_change_profile" htmlFor="uploadAvatarLabel">
            {isLoading && (
              <Skeleton
                className="avatar-placeholder"
              />
            )}
            <img
              src={Ava()}
              alt="avatar"
              style={{ display: isLoading ? "none" : "block" }}
              onLoad={onLoad}
            />
          </label>
          {/* <img src={`${process.env.REACT_APP_FRONTEND_URL}img/avatar/profile-test.jpg`} className="icon" alt="[talking icon]" /> */}
          <div className="profile__top">
            <div className="profile__name">{userInfo?.username}</div>
            <div className={rating_bar}>
              {/* <div className="profile__rating-mask" style={{ width: `${sellerRateShow?.rating}%` }}></div>
                <div className="profile__rating-bar"></div> <i className="las la-check-circle"></i> */}
              {userInfo.rating?.isExist && (
                <>
                  <i className="las la-star"></i> <span className="rate">{_.round(userInfo.rating?.rating / 20, 1)}</span> <span className="deal__num">({userInfo.rating?.number})</span>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="profile__data">
          <div className="profile__location">{userInfo?.location && GetCountryName(userInfo?.location)}</div>
          <p className="profile__description" dir="ltr">{userInfo?.description}</p>
          <ul className="profile__tags">
            {userInfo?.tags?.map(e => { if (e !== "") { return (<li key={e}>#{e}</li>); } return (""); })}
          </ul>
        </div>
        <ButtonToolbar className="profile__mid-toolbar btn-toolbar--center">
          {renderButton()}
        </ButtonToolbar>
      </div>
    </>
  );
};

export default ProfileMain;
