// action
import { createAction, createReducer, createSelector } from "@reduxjs/toolkit";

export const checkedNsfwProfile = createAction("profile/checkedNsfwProfile");
export const gotPromoteProfile = createAction("profile/gotPromoteProfile");
export const gotUserInfo = createAction("profile/gotUserInfo");
export const gotProfileContent = createAction("profile/gotProfileContent");
export const changedProfileContent = createAction("profile/changedProfileContent");
export const unshiftProfileContent = createAction("profile/unshiftProfileContent");
export const deletedProfilePhoto = createAction("profile/deletedProfilePhoto");

// reducer
const initialState = {
  isNsfwContent: false,
  promotedProfile: [],
  userInfo: null,
  isMe: null,
  profileContents: [],
  creatorRate: [],
  supportedCountry: null,
};

const profileReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(gotPromoteProfile.type, (state, action) => ({
      ...state,
      promotedProfile: action.payload,
    }))
    .addCase(checkedNsfwProfile.type, (state, action) => ({
      ...state,
      isNsfwContent: action.payload,
    }))
    .addCase(gotUserInfo.type, (state, action) => ({
      ...state,
      userInfo: action.payload,
      isMe: action.payload.isMe,
      creatorRate: action.payload.rating,
      supportedCountry: action.payload.supportedCountry,
    }))
    .addCase(gotProfileContent.type, (state, action) => ({
      ...state,
      profileContents: action.payload,
    }))
    .addCase(changedProfileContent.type, (state, action) => ({
      ...state,
      profileContents: action.payload,
    }))
    .addCase(unshiftProfileContent.type, (state, action) => {
      state.profileContents.unshift({ id: action.payload });
    })
    .addCase(deletedProfilePhoto.type, (state, action) => {
      const index = state.profileContents.findIndex((c) => c.id === action.payload[0]);
      state.profileContents.splice(index, 1);
    });
});

export default profileReducer;
// export const getAuthStore = createSelector();
